.date_time_input{
  position: relative;

  &_selector {
    position: absolute;
    height: auto;
    margin-top: 45px;
    z-index: $datePickerSelectorZIndex;
    border: 2px solid rgba(#000, 0.08);
    border-radius: 3px;
    box-shadow: 2px 2px 10px 1px rgba(0, 0, 0, 0.08);
    display: flex;
    visibility: hidden;
    background-color: #fff;

    &_above{
      bottom: 10px;
      margin-top:0;
      visibility: visible;
    }

    &_below{
      top:0;
      visibility: visible;
      bottom: unset;
    }
  }

  &_wrapper{
    .date_time_input_selector_below, 
    .date_time_input_selector_above{
      top: 0;
      left: 30px;
      bottom: auto;
    }
  }
}