@import "react-calendar/dist/Calendar.css";

$datePickerPrimary: $primaryColor;
$datePickerPrimaryActive: $primaryActiveColor;
$datePickerSecondary: #ddd;

.date_time_picker {
  position: relative;
  max-width: 285px;

  &.error {
    border-color: $dangerColor !important;
  }

  &_selector {
    background-color: #fff;
    position: absolute;
    height: auto;
    z-index: $datePickerSelectorZIndex;
    border: 2px solid rgba(#000, 0.08);
    border-radius: 3px;
    box-shadow: 2px 2px 10px 1px rgba(0, 0, 0, 0.08);
    display: flex;
    flex-direction: column;

    &_mode {
      display: flex;
      justify-content: center;
      background-color: #fff;
      border-bottom: 1px solid $datePickerSecondary;

      &__button {
        margin: 2px 4px;
        padding: 3px 12px;
        transition: all $transitionTime;

        &:hover,
        &:focus {
          .date_picker_selector_mode__button__icon {
            path {
              fill: $datePickerPrimaryActive;
            }
          }
          .date_picker_selector_mode__button__title {
            color: $datePickerPrimaryActive;
          }
        }

        &.active {
          .date_picker_selector_mode__button__icon {
            path {
              fill: $datePickerPrimary;
            }
          }
          .date_picker_selector_mode__button__title {
            color: $datePickerPrimary;
          }
        }
      }
    }
  }

  &_calendar {
    border: none;
    height: 284px;
  }

  .react-calendar {
    &__navigation {
      margin-bottom: 0;

      *:disabled {
        opacity: 0.2;
        background-color: inherit;
      }

      &__label {
        text-transform: uppercase;
        font-weight: 500;
        line-height: 17px;
        font-size: 12px;

        &:hover,
        &:focus {
          background: $datePickerPrimaryActive;
        }
      }

      button {
        &:enabled {
          &:hover,
          &:focus {
            background-color: inherit;
            color: $datePickerPrimaryActive;
          }
        }
      }
    }

    &__tile {
      height: 34px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 3px;
      font-size: 12px;

      &:hover:not(:disabled) {
        background-color: $datePickerPrimaryActive;
        color: #fff;
        abbr {
          color: #fff;
        }
      }

      &:disabled {
        opacity: 0.2;
        background-color: inherit;
      }

      &--now {
        background-color: $datePickerSecondary;
        border: 1px solid $datePickerSecondary;
      }

      &--active {
        background-color: $datePickerSecondary;
        color: #fff;
        abbr {
          color: #303657;
        }
      }

      abbr {
        font-size: 12px;
      }

      &--rangeEnd,
      &--rangeStart {
        background-color: $datePickerPrimary;
        abbr {
          color: #fff;
          font-size: 12px;
        }
        &.react-calendar__month-view__days__day--neighboringMonth {
          background-color: $datePickerPrimary !important;
          abbr {
            color: #fff !important;
          }
        }
      }
      &--active.react-calendar__month-view__days__day--neighboringMonth {
        background-color: #cccccc;
        abbr {
          color: #303657;
        }
      }
    }

    &__month-view__weekdays__weekday {
      abbr {
        font-size: 10px;
        border-bottom: none;
        text-decoration: none;
      }
    }

    &__month-view__days__day--neighboringMonth {
      background-color: rgb(245 245 245)!important;
    }
  }

  &_time_selector {
    border-top: 1px solid #fafafa;
    margin-top: 5px;
    display: flex;
    align-items: center;
    padding: 0 13px;
    max-width: 350px;

    &__icon {
      padding-right: 15px;
    }

    &__action_button {
      padding: 2px;

      width: 100%;
    }

    &__section {
      display: flex;
      flex-direction: column;
      align-items: center;

      input {
        text-align: center;
      }
    }

    &__section_separator {
      padding: 0 3px;
    }
  }
}
