.mileage_details {
  $selectedPassengerTextColor: $primaryColor;
  $selectedPassengerDefaultPlaceTextColor: rgba($primaryColor, 1);

  .mileage_details_map_wrapper {
    height: 400px;
    width: 100%;
    position: relative;
  }

  .mileage_details_modal {
    display: flex;
  }

  .mileage_details_modal_field {
    padding: 10px 10px 10px 0px;
  }

  .mileage_details_modal_button {
    margin: 30px 0px 0px 10px;
  }

  .mileage_details_modal_value_button_label {
    height: 40px;
    width: 120px;
    border-radius: 3px;
  }

  .mileage_details_modal_value_button_content {
    color: #fff;
    font-size: 14px;
  }

  .mileage_details_modal_numeric_input {
    width: 80px;
    height: 40px;
  }

  .mileage_details_modal_difference_input {
    &__positive {
      color: #329632 !important;
    }

    &__negative {
      color: #bb4324 !important;
    }
  }

  .mileage_details_modal_diferrence_label {
    width: 80px;
    height: 40px;
    border-color: transparent;
  }

  .mileage_details_modal_view_container {
    margin-top: 10px;
  }

  .mileage_details_modal_text {
    font-size: 14px;
  }

  .mileage_details_tools {
    width: 50px;
    top: 0;
    right: 0;
    position: absolute;
    background-color: #f3f3f3;
    width: fit-content;
    max-width: 100%;
    display: flex;
    align-items: center;
    border-bottom-left-radius: 5px;

    .mileage_details_tools_button {
      padding: 8px 8px 6px 8px;
      width: 32px;
      height: 32px;
      margin: 5px;
      border-radius: 100%;

      &__icon {
        path {
          fill: #fff;
        }
      }

      &.disabled {
        background-color: #aaa;
      }
    }
  }

  .map_marker {
    &.default {
      path {
        fill: $selectedPassengerDefaultPlaceTextColor;
      }
    }
  }

  .mileage_details_passengers {
    margin-top: 10px;

    .mileage_details_passengers_item {
      display: flex;
      align-items: center;
      margin: 7px 0;
      position: relative;
      cursor: pointer;

      & > * {
        font-size: 14px;
      }

      &__icon {
        margin-right: 5px;
        margin-top: -2px;
      }

      &__name {
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      &.selected {
        .mileage_details_passengers_item__icon {
          path {
            fill: $selectedPassengerTextColor;
          }
        }

        .mileage_details_passengers_item__name {
          color: $selectedPassengerTextColor;
        }
      }
    }
  }
  .mileage_details_routes {
    margin-top: 10px;

    .mileage_details_route {
      list-style-type: none;
      display: flex;
      align-items: center;
      margin: 2px 0;
      position: relative;

      & * {
        font-size: 14px;
        text-overflow: ellipsis;
        overflow: hidden;
        display: block;
      }

      .index {
        width: 10px;
        text-align: center;
      }

      .dot {
        width: 6px;
        height: 6px;
        background-color: #000;
        border-radius: 100%;
        z-index: 2;
        margin: 0 7px;
      }

      &__icon {
        margin-right: 5px;
      }

      &__connection_line {
        left: 20.5px !important;
      }

      .place {
        width: calc(100% - 70px - 65px);
        display: flex;
        align-items: center;

        &__name {
          width: calc(100% - 20px);
        }
      }

      .distance {
        width: 90px;
        padding: 3px 7px;
      }

      .completion_date {
        width: 150px;
        padding: 3px 7px;
      }
    }
  }
}

.mileage_details_edit_summary {
  &__mileage_number_form_field_content {
    line-break: normal;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &__connection_line {
    left: 19.5px !important;
  }

  .mileage_details_edit_summary_route {
    .mileage_details_edit_summary_route_waypoint_list {
      &__header {
        display: flex;
        gap: 10px;
      }

      &__heading {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        font-size: 14px;

        &.place {
          flex: 3;
        }

        &.distance {
          flex: 1;
        }
      }

      &__row {
        display: flex;
        gap: 10px;

        * {
          font-size: 14px;
        }

        &.faded {
          opacity: $mediumOpacity;
        }
      }

      &__row_cell {
        text-overflow: ellipsis;
        overflow: hidden;

        &.place {
          flex: 3;
          display: flex;
          align-items: center;

          .index {
            width: 10px;
          }

          .dot {
            width: 6px;
            height: 6px;
            background-color: #000;
            border-radius: 100%;
            z-index: 2;
            margin: 0 7px;
          }

          .place_name {
            width: calc(100% - 16px);
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }
        }

        &.distance {
          flex: 1;
        }
      }
    }
  }
}
