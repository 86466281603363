$asideNavigationItemColor: #9d9da6;
$asideNavigationItemActiveColor: #ffffff;

.app_aside {
  width: $asideCollapsedWidth;
  z-index: $asideMenuPanelZIndex;
  background-color: $asideBackgroundColor;
  position: fixed;
  height: 100vh;
  transition: width $transitionTime;

  &.expanded {
    width: $asideExpandedWidth;
  }

  .app_aside_logo_wrapper,
  .app_aside_marketplace_logo_wrapper,
  .app_aside_logo_full_wrapper {
    height: 50px;
    padding: 5px 0 5px 5px;
    position: relative;
  }

  .app_aside_logo_wrapper {
    border-bottom: 1px solid #000;
  }

  .app_aside_logo {
    width: auto;
    height: 38px;
    max-height: fit-content;
    path {
      fill: $appLogoColor;
    }
  }

  .app_aside_marketplace_link {
    height: 50px;
    padding: 5px 0 5px 4px;
    position: relative;
    display: block;
    overflow: hidden;
    width: auto;
  }

  .app_aside_marketplace_logo {
    &_small{
      width: auto;
      height: 38px;
      max-height: fit-content;   

      path {
        fill: $marketplaceLogoColor;
      }
    }

    &_full{
       height: 38px;
       
      path {
        fill: $marketplaceLogoColor;
      }
    }
  }

  .app_aside_navigation {
    padding: 10px 5px;
    height: calc(100% - 200px);
    position: relative;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: #62627c;
    }

    .app_aside_navigation_list_item {
      position: relative;
      display: block;
      width: 100%;

      &__attention_counter {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 26px;
        background-color: $asideNavigationItemColor;
        color: $asideBackgroundColor;
        font-size: 10px;
        font-weight: 500;
        margin: 0 4px 0 auto;
        padding: 2px 4px;
        border-radius: 6px;
      }

      &__content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        padding: 8px 0 8px 8px;
        margin: 2px;
        border-radius: 3px;

        &.highlighted {
          background-color: #2a2a3c;
        }

        &.active {
          background-color: $primaryColor;

          .app_aside_navigation_list_item__icon {
            path {
              fill: #fff;
            }
          }
          .app_aside_navigation_list_item__text {
            color: #fff;
          }
        }

        &:hover,
        &:focus {
          .app_aside_navigation_list_item__icon {
            width: 20px;
            path {
              fill: $asideNavigationItemActiveColor;
            }
          }

          .app_aside_navigation_list_item__text {
            margin-left: 10px;
            color: $asideNavigationItemActiveColor;
          }

          .app_aside_navigation_list_item__toggle_icon {
            path {
              fill: $asideNavigationItemActiveColor;
            }
          }
        }
      }

      &__content_data {
        display: flex;
        align-items: center;
        position: relative;
        width: calc(100% - 35px);
      }

      &__icon {
        width: 20px;
        max-width: 20px;
        path {
          fill: $asideNavigationItemColor;
        }
      }

      &__icon_wrapper {
        width: 20px;
        max-width: 20px;
      }

      &__text {
        margin-left: 10px;
        color: $asideNavigationItemColor;
        font-size: 14px;
        display: block;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        position: relative;
        width: 100%;
      }

      &__toggle_button {
        padding: 3px 12px;
      }

      &__toggle_icon {
        path {
          fill: $asideNavigationItemColor;
        }
      }

      .app_aside_navigation_list_item_link_button {
        padding: 0;
        width: 100%;
        &__content {
          width: 100%;
        }
      }
    }
  }

  .app_aside_pin_button {
    position: absolute;
    right: -10px;
    bottom: 10px;
    background-color: #ffffff;
    border: 1px solid #eeeeee;
    padding: 7px 11px;
  }
}
