@import "./contract-details/contract-details.scss";

.billings {
  .billings_map_wrapper {
    height: 400px;
    width: 100%;
    position: relative;
  }

  .billings_submit_buttons_container {
    width: 1280px;
    max-width: 90vw;
  }
}
.route_details {
  .table__td {
    vertical-align: unset;
  }

  .route_details_button_wrapper {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
  }

  &_error_message{
    font-size: 0.8rem;
    color: #df4f5d;
    display: block;
    white-space: pre-wrap;
  }
}
