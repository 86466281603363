.order_optimizer_details {
  display: flex;
  flex-direction: row;

  &__left {
    width: 50%;

    &__button {
      margin-top: 20px;
      width: 100%;

      &__icon {
        margin-left: 10px;
        * {
          color: #fff;
        }
      }
    }
  }

  &__right {
    width: 49%;
    margin-left: 20px;

    &__info {
      display: flex;
      flex-direction: row;

      &__left {
        width: 45%;
        display: flex;
        flex-direction: row;

        &__text {
          font-size: 30px;
          font-weight: 500;
          width: 100%;
        }
      }

      &__right {
        width: 55%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;

        &__badge {
          width: auto;
          max-width: 400px;
          background-color: #d9d9d9;
          color: #000;
          height: 40px;
          border-radius: 20px;
          margin-left: 30px;
          padding: 0 20px;
          line-height: 40px;
          text-align: center;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;

    &__left {
      width: 100%;
    }

    &__right {
      width: 100%;
      margin-top: 20px;
      margin-bottom: 20px;
      margin-left: 0px;

      &__info {
        display: flex;
        flex-direction: row;

        &__left {
          width: 50%;
          display: flex;
          flex-direction: row;
        }

        &__right {
          width: 50%;
          display: flex;
          flex-direction: row;

          &__badge {
            width: auto;
            background-color: #d9d9d9;
            color: #000;
            height: 40px;
            border-radius: 20px;
            margin-left: 10px;
            padding: 0 20px;
            line-height: 40px;
            text-align: center;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
    }
  }
}

.order_optimizer_details_map_wrapper {
  width: 100%;
  height: 600px;
  position: relative;
}

.order_optimizer_details_orders_table {
  border: 1px solid #cacaca;
  border-radius: 8px;
  margin-top: 10px;

  &__humanId {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
  }

  @media (max-width: 768px) {
    margin-top: 30px;
  }
}

.order_optimizer_details_without_driver_table {
  border: 1px solid #cacaca;
  border-radius: 8px;
  margin-top: 10px;

  @media (max-width: 768px) {
    margin-top: 30px;
  }
}

.order_optimizer_details_driver_table {
  border-left: 1px solid #cacaca;
  border-top: 1px solid #cacaca;
  border-right: 1px solid #cacaca;
  border-start-end-radius: 8px;
  border-start-start-radius: 8px;
  margin-top: 10px;

  @media (max-width: 768px) {
    margin-top: 30px;
  }
}

.order_optimizer_details_driver_horizontal_slider_table {
  display: flex;
  flex-direction: row;
  border-left: 1px solid #cacaca;
  border-right: 1px solid #cacaca;
  border-bottom: 1px solid #cacaca;
  border-end-start-radius: 8px;
  border-end-end-radius: 8px;
  align-items: flex-start;

  &__cell {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-top: 15px;

    &__text {
      margin-left: 8px;
      font-size: 0.9rem;
      font-weight: 300;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &__cell_input {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-overflow: ellipsis;
    margin-top: 10px;
    margin-bottom: 10px;

    &__text {
      margin-left: 8px;
      font-size: 0.9rem;
      font-weight: 300;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &__label {
      width: 110px;
    }
  }

  &__cell_margin {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-top: 15px;

    &__text {
      margin-left: 8px;
      font-size: 0.9rem;
      font-weight: 300;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &__slider {
    flex: 4;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 23px;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
    margin-top: -15px;
    height: 280px;

    &__cell {
      flex: 1;
      align-items: center;
      justify-content: flex-start;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      border-bottom: 1px solid #cacaca;

      &__text {
        margin-left: 8px;
        font-size: 0.9rem;
        font-weight: 300;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      &__input {
        width: 97%;
        margin-left: 10px;
      }
    }

    &__cell_margin {
      flex: 1;
      align-items: center;
      justify-content: flex-start;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      &__text {
        margin-left: 8px;
        font-size: 0.9rem;
        font-weight: 300;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    &__slider {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom: 1px solid #cacaca;
    }
  }

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;

    &__heading_section {
      display: flex;
      flex-direction: column;
      margin-top: 30px;

      &__left {
        width: 100%;
      }

      &__center {
        width: 100%;
      }

      &__right {
        width: 100%;
      }
    }
  }
}

.order_optimizer_details_hidden_details {
  display: flex;
  flex-direction: column;

  &__heading_section {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    margin-top: 30px;

    &__left {
      width: 16%;
    }

    &__center {
      width: 54%;
    }

    &__right {
      width: 30%;
    }
  }

  &__center_section {
    .table__td {
      vertical-align: top;
      align-content: flex-start;
      &:first-of-type {
        padding-left: 0px;
      }
      &:last-of-type {
        padding-right: 0px;
      }
    }

    &__left {
      width: 70%;
    }

    &__right {
      width: 30%;
    }
  }

  &__lower_section {
    display: flex;
    flex-direction: row;
    gap: 15px;
    margin-top: 10px;

    &__left {
      display: flex;
      flex-direction: row;
      width: 70%;
      border: 1px solid #cacaca;
      border-radius: 8px;
      height: 40px;

      &__driver {
        width: 22.5%;
        padding-left: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      &__order {
        display: flex;
        flex-direction: row;
        border-left: 1px solid #cacaca;
        width: 77.5%;
        align-items: center;
        overflow: hidden;

        &__human_id {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 8px;
          padding: 10px 8px;
          width: 12.5%;

          &__text {
            font-size: 14px;
          }
        }

        &__text {
          font-size: 14px;
          padding: 10px 8px;
          width: 68.75%;
        }

        > .button_full_route {
          width: 12.5%;
          overflow: hidden;
          > .button {
            padding: 5px 4px;
          }
        }

        > .button_route {
          width: 6.25%;
          padding: 0px 7px 0px 8px;

          > .button .button_content {
            font-size: 1rem;
          }
        }
      }
    }

    &__right {
      width: 29.75%;
      border: 1px solid #cacaca;
      border-radius: 8px;
      display: flex;
      flex-direction: column;

      &__driver {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        border-bottom: 1px solid #cacaca;
        height: 54px;

        &__cell {
          display: flex;
          align-items: center;
          width: 25%;
          font-size: 14px;
          padding: 10px;
        }
      }

      &__text {
        font-size: 14px;
        font-weight: 500;
        margin-left: 20px;
        height: 25px;
        margin-top: 5px;
      }

      &__summary {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        border-top: 1px solid #cacaca;
        height: 54px;

        &__cell {
          display: flex;
          align-items: center;
          width: 25%;
          font-size: 14px;
          padding: 10px;
        }
      }
    }
  }

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;

    &__heading_section {
      display: flex;
      flex-direction: column;
      margin-top: 30px;

      &__left {
        width: 100%;
      }

      &__center {
        width: 100%;
      }

      &__right {
        width: 100%;
      }
    }
  }
}

.order_optimizer_details_hidden_details_order {
  border: 1px solid #cacaca;
  border-radius: 8px;
  margin-top: 10px;
  margin-left: -6px;
  margin-right: 8px;

  &__text {
    margin-left: 15px;
  }

  &__humanId {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
  }

  @media (max-width: 768px) {
    margin-left: 0px;

    &__text {
      margin-top: 20px;
      margin-left: 0px;
    }
  }
}

.order_optimizer_details_hidden_details_driver {
  border: 1px solid #cacaca;
  border-radius: 8px;
  margin-top: 10px;
  margin-left: 8px;

  &__text {
    margin-left: 15px;
  }

  @media (max-width: 768px) {
    margin-left: 0px;

    &__text {
      margin-top: 20px;
      margin-left: 0px;
    }
  }
}

.order_optimizer_details_filter {
  display: flex;
  flex-direction: column;
  margin-bottom: 2px;
  margin-right: 20px;

  &__header {
    display: flex;
    align-items: flex-end;

    &__text {
      font-size: 16px;
      font-weight: 500;
    }
  }

  &__select {
    display: flex;
    justify-content: row;

    &__input_wrapper {
      display: flex;
      align-items: center;
      &__button {
        padding: 9px 12px;

        &__icon {
          * {
            color: #fff;
          }
        }
      }
    }
  }
}

.order_optimizer_details_hidden_details_center_tables_order {
  border: 1px solid #cacaca;
  border-radius: 8px;

  &__text {
    margin-left: 15px;
  }

  &__humanId {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
  }

  @media (max-width: 768px) {
    margin-left: 0px;

    &__text {
      margin-top: 20px;
      margin-left: 0px;
    }
  }
}

.order_optimizer_details_hidden_details_center_tables_driver {
  border: 1px solid #cacaca;
  border-radius: 8px;
  margin-bottom: 10px;

  .table__td {
    &:first-of-type {
      padding-left: 8px;
    }
    &:last-of-type {
      padding-right: 8px;
    }
  }
  &__text {
    margin-left: 15px;
  }

  @media (max-width: 768px) {
    margin-left: 0px;

    &__text {
      margin-top: 20px;
      margin-left: 0px;
    }
  }
}

.order_optimizer_details_hidden_details_center_tables_orders {
  display: flex;
  justify-content: row;

  &__left {
    width: 22.5%;

    & > .order_summary {
      padding: 15px 20px;
      font-size: 16px;
      font-weight: 400;

      & > .order_id {
        display: flex;
        border-bottom: 1px solid #c6c6c6;

        > .dot {
          margin-top: 7px;
          margin-right: 8px;
          border-radius: 50%;
          width: 16px;
          height: 16px;
        }

        > .button {
          padding: 0px;
          > .button_content {
            > .text {
              text-align: center;
              font-size: 20px;
              font-weight: 600;
            }
          }
        }
      }

      & > .driver_name {
        padding: 0px 12px;
        font-size: 12px;
        border-radius: 10px;
        background-color: #d4eaff;
        width: fit-content;
      }
    }
  }

  &__right {
    width: 77.5%;
    border-left: 1px solid #cacaca;

    &__order {
      .table__td {
        &:first-of-type {
          padding-left: 8px;
        }
        &:last-of-type {
          align-content: center;
          padding-bottom: 4px;
          padding-top: 6px;
        }
      }

      * > .last_driver_time {
        font-weight: 600;
      }
    }

    &__summary {
      height: 40px;

      > .content {
        display: flex;
        width: 100%;

        > .human_id {
          width: 12.5%;
        }

        > .address {
          width: 31.25%;
          font-size: 16px;
          font-weight: 600;
          padding: 8px;
        }

        > .driver_time {
          width: 18.75%;
          padding: 8px;
        }

        > .driver_halting_time {
          width: 18.75%;
          padding: 8px;
        }

        > .driver_distance {
          width: 12.5%;
          padding: 8px;
        }

        > .actions {
          width: 6.25%;
          align-content: center;
          padding: 0px 8px;
          overflow: hidden;
        }
      }
    }
  }
}
