.planning_order_order {
    &_map,
    &_loader {
        height: 250px;
    }

    &_loader {
        display: flex;
        justify-content: center;
    }
}