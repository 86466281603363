.notification_success {
  font-family: $fontFamily !important;
  background-color: $successColor !important;
  padding-right: 18px !important;

  .nx-message {
    color: #fff !important;
  }

  .nx-message-icon {
    path {
      fill: #fff !important;
      opacity: $highOpacity;
    }
  }
}

.notification_warning {
  font-family: $fontFamily !important;
  background-color: $warningColor !important;
  padding-right: 18px !important;

  .nx-message {
    color: #fff !important;
  }

  .nx-message-icon {
    path {
      fill: #fff !important;
      opacity: $highOpacity;
    }
  }
}

.notification_error {
  font-family: $fontFamily !important;
  background-color: $dangerColor !important;
  padding-right: 18px !important;

  .nx-message {
    color: #fff !important;
  }

  .nx-message-icon {
    path {
      fill: #fff !important;
      opacity: $highOpacity;
    }
  }
}

.notification_info {
  font-family: $fontFamily !important;
  background-color: $infoActiveColor !important;
  padding-right: 1px !important;


  .nx-message {
    color: #fff !important;
  }

  .nx-message-icon {
    path {
      fill: #fff !important;
      opacity: $highOpacity;
    }
  }
}

.nx-with-animation{ 
  &::after{
    content: 'x';
    font-size: 14px;
    position: absolute;
    right: 10px;
    top: 4px;
  }
}