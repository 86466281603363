@import "react-calendar/dist/Calendar.css";

$datePickerPrimary: $primaryColor;
$datePickerPrimaryActive: $primaryActiveColor;
$datePickerSecondary: #ddd;

.date_picker {
  position: relative;
  height: 284px;
  background-color: #fff;

  &_calendar {
    border: none;
  }

  .react-calendar {
    width: 100%;
    &__navigation {
      margin-bottom: 0;

      *:disabled {
        opacity: 0.2;
        background-color: inherit;
      }

      &__label {
        text-transform: uppercase;
        font-weight: 500;
        line-height: 17px;
        font-size: 12px;

        &:hover,
        &:focus {
          background: $datePickerPrimaryActive;
        }
      }

      button {
        &:enabled {
          &:hover,
          &:focus {
            background-color: inherit;
            color: $datePickerPrimaryActive;
          }
        }
      }
    }

    &__tile {
      height: 34px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 3px;
      font-size: 12px;

      &:hover:not(:disabled) {
        background-color: $datePickerPrimaryActive;
        color: #fff;
        abbr {
          color: #fff;
        }
      }

      &:disabled {
        opacity: 0.2;
        background-color: inherit;
      }

      &--now {
        background-color: $datePickerSecondary;
        border: 1px solid $datePickerSecondary;
      }

      &--active {
        background-color: $datePickerSecondary;
        color: #fff;
        abbr {
          color: #303657;
        }
      }

      abbr {
        font-size: 12px;
      }

      &--rangeEnd,
      &--rangeStart {
        background-color: $datePickerPrimary;
        abbr {
          color: #fff;
          font-size: 12px;
        }
        &.react-calendar__month-view__days__day--neighboringMonth {
          background-color: $datePickerPrimary !important;
          abbr {
            color: #fff !important;
          }
        }
      }
      &--active.react-calendar__month-view__days__day--neighboringMonth {
        background-color: #cccccc;
        abbr {
          color: #303657;
        }
      }
    }

    &__month-view__weekdays__weekday {
      abbr {
        font-size: 10px;
        border-bottom: none;
        text-decoration: none;
      }
    }

    &__month-view__days__day--neighboringMonth {
      background-color: rgb(245 245 245)!important;
    }
  }
}
