.mileage_update {
  $selectedPassengerTextColor: $primaryColor;
  $selectedPassengerOnboardPlaceTextColor: #329632;
  $selectedPassengerOutboardPlaceTextColor: #bb4324;
  .mileage_update_passenger_list {
    list-style: none;
  }

  .mileage_update_passenger_list_item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    &:not(:first-child) {
      margin: 10px 0;
    }

    &__drag_button {
      border: none;
      background: none;
      margin-right: 5px;
      padding: 2px;
    }

    &__index {
      width: 30px;
    }

    &__passenger_name {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &.selected {
      .mileage_update_passenger_list_item__index {
        path {
          fill: $selectedPassengerTextColor;
        }
      }

      .mileage_update_passenger_list_item__passenger_name {
        color: $selectedPassengerTextColor;
      }
    }
  }

  .mileage_update_address_route_list_item {
    background-color: #fff;
    margin-bottom: 10px;

    &:not(:first-child) {
      margin: 10px 0;
    }

    &__top_row {
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
      border: 1px solid #dddddd;
      padding: 10px 8px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 5px;
    }

    &__drag_button {
      border: none;
      background: none;
      padding: 2px;
    }

    &__index {
      padding: 10px 12px;
    }

    &__address {
      flex: 1;
    }
  }

  .mileage_update_passenger_icon {
    position: relative;
    display: flex;
    align-items: center;

    &__content {
      position: absolute;
      left: 0;
      width: 14px;
      font-size: 12px;
      text-align: center;
    }
  }

  .mileage_update_summary {
    &__mileage_number_form_field_content {
      line-break: normal;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &__connection_line {
      left: 19.5px !important;
    }

    .mileage_update_summary_route {
      .mileage_update_summary_route_waypoint_list {
        &__header {
          display: flex;
          gap: 10px;
        }

        &__heading {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          font-size: 14px;

          &.place {
            flex: 3;
          }

          &.distance {
            flex: 1;
          }

          &.time {
            flex: 1;
          }
        }

        &__row {
          display: flex;
          gap: 10px;

          * {
            font-size: 14px;
          }

          &.first_higlight * {
            color: $selectedPassengerOnboardPlaceTextColor;
          }

          &.last_higlight * {
            color: $selectedPassengerOutboardPlaceTextColor;
          }

          &.faded {
            opacity: $mediumOpacity;
          }
        }

        &__row_cell {
          text-overflow: ellipsis;
          overflow: hidden;

          &.place {
            flex: 3;
            display: flex;
            align-items: center;

            .index {
              width: 10px;
            }

            .dot {
              width: 6px;
              height: 6px;
              background-color: #000;
              border-radius: 100%;
              z-index: 2;
              margin: 0 7px;
            }

            .place_name {
              width: calc(100% - 16px);
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
            }
          }

          &.distance {
            flex: 1;
          }

          &.time {
            flex: 1;
          }
        }
      }
    }
  }
}

.mileage_update_route_validation_modal {
  &__error_list {
    padding-left: 20px;
  }
}
