@import "./cargo-raily/add/billings-cargo-raily.scss";
@import "./cargo-taxi/add/billings-cargo-taxi.scss";
@import "./raily-taxi/add/billings-raily-taxi.scss";
@import "./taxi-driver/add/billings-taxi-driver.scss";
@import "./taxi-raily/add/billings-taxi-raily.scss";
@import "./cargo-taxi/edit/billings-cargo-taxi.scss";
@import "./taxi-driver/edit/billings-taxi-driver.scss";
@import "./taxi-driver/view/billings-taxi-driver.scss";
@import "./cargo-taxi/view/billings-cargo-taxi.scss";
@import "./taxi-taxi/add/billings-taxi-taxi.scss";
@import "./taxi-taxi/edit/billings-taxi-taxi.scss";
@import "./taxi-taxi/view/billings-taxi-taxi.scss";
@import "./common/related-billings/related-billings.scss";


.billings {
  .billings_tools {
    width: 50px;
    top: 0;
    right: 0;
    position: absolute;
    background-color: #f3f3f3;
    width: fit-content;
    max-width: 100%;
    display: flex;
    align-items: center;
    border-bottom-left-radius: 5px;

    .billings_tools_button {
      padding: 8px 8px 6px 8px;
      width: 32px;
      height: 32px;
      margin: 5px;
      border-radius: 100%;

      &__icon {
        path {
          fill: #fff;
        }
      }

      &.active {
        background-color: $successColor;
      }
    }
  }

    &_item_table_status {
      width: 5px;
      height: 40px;
  
      &.created {
        background-color: $createdStatusColor;
      }
  
      &.accepted {
        background-color: $acceptedStatusColor;
      }
  
      &.rejected {
        background-color: $rejectedStatusColor;
      }
  
      &.reopened {
        background-color: $reopenedStatusColor;
      }
  
      &.reopen_request {
        background-color: $reopenRequestStatusColor;
      }
    }

    &_contributions_row {
      height: 55px;
    }

    &_contributions_not_required_row {
      display: flex;
      align-items: center;
      padding-bottom: 15px;
    }

    &_contributions_add_button {
      padding: 10px 10px 0 0;
    }

    &_contributions_delete_button {
      margin-bottom: 15px;
    }

    &_history_messenger_container {
      height: 800px;
    }

    &_history {
      height: 95%;
      width: 100%; 
      overflow-y: auto; 
    }
  }
