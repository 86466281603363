@import "leaflet/dist/leaflet.css";

.map {
  width: 100%;
  height: 100%;
  min-height: 200px;

  .map_container {
    width: 100%;
    height: 100%;
    z-index: 0;
    position: relative;
  }

  &__message_box {
    z-index: 1000;
    background: #fff;
    border-radius: 10px;
    padding: 10px 5px;
    position: absolute;
    bottom: 30px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    max-width: 80%;
    width: 350px;
    box-shadow: 0px 16px 9px -5px rgba(66, 68, 90, 0.6);
  }
}

.map_marker {
  &.standard {
    path {
      fill: #3386cf;
    }
  }
}

.map_marker_icon_content_wrapper {
  font-size: 13px;
  position: absolute;
  left: 0;
  top: 3px;
  color: #fff;
  display: flex;
  justify-content: center;
  width: 100%;
  path {
    fill: #fff !important;
  }
}
