.related_billings {
  background: gainsboro;
  &_table_icon {
    path {
      fill: #fff;
    }
  }

  &_actions {
    display: flex;
    gap: 0 6px
  }
}