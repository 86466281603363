@mixin breakpoint($name) {
    @if $name == "xsmallscreen" {
        @media all and (min-width: $xsmallscreen) {
            @content;
        }
    } @else if $name == "smallscreen" {
        @media all and (min-width: $smallscreen) {
            @content;
        }
    } @else if $name == "midscreen" {
        @media all and (min-width: $midscreen) {
            @content;
        }
    } @else if $name == "largescreen" {
        @media all and (min-width: $largescreen) {
            @content;
        }
    } @else if $name == "xlargescreen" {
        @media all and (min-width: $xlargescreen) {
            @content;
        }
    } @else if $name == "xxlargescreen" {
        @media all and (min-width: $xxlargescreen) {
            @content;
        }
    } @else {
        @if type-of($name) != "number" {
            @warn "This breakpoint does not exist:  #{$name}";
        }
        @media all and (min-width: $name) {
            @content;
        }
    }
}