$abandonedStatusColor: #ffe83c;

.order_abandoned_listing {
  display: flex;
  flex-flow: column;

  &__pagination_wrapper {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
  }

  &_table {
    &_route {
      display: block;
      white-space: normal;
    }
  }

  &_tools {
    display: flex;
    gap: 0 8px;
  }

  &_sort {
    margin-left: auto;
  }

  &_filters {
    display: flex;
    position: relative;
    flex-direction: column;

    &__select {
      width: 200px !important;
      height: 37px;

      .select__option {
        font-size: 14px;
      }

      .select__input {
        font-size: 14px;
      }
    }

    &__list {
      display: flex;
      flex-flow: row wrap;
      margin: 5px 0;
      gap: 5px;


      .button{
        padding: 2px;
      }
    }

    &_badge {
      display: flex;
      height: min-content;
      padding: 3px 6px;
      max-width: 140px;
      border: 1px solid #ececec;
      background-color: #fff;
      border-radius: 4px;
      font-size: 14px;
      width: -moz-fit-content;
      width: fit-content;

      &__text {
        font-size: 14px;
        padding: 0 8px 0 0;
        width: calc(100% - 16px);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      &__button {
        padding: 2px;
        height: 16px;
        width: 16px;
      }
    }
  }
}
